<template>
  <v-container fluid class="pt-10">
    <v-row no-gutters justify="center" class="pb-10">
      <!-- 타이틀 -->
      <v-col
        cols="12"
        class="pt-4 pb-8 reform_primary--text text-center text-h5 font-weight-bold"
        >신청서 작성
      </v-col>

      <!-- 테이블 -->
      <v-col cols="12" md="8" lg="8" xl="8">
        <v-container fluid class="pa-0 custom-table">
          <!-- 사진탭 -->
          <v-row
            no-gutters
            justify="center"
            align="center"
            class="custom-table-row"
            v-for="(attachmentItem, index) in form.attachment"
            :key="index + 'A'"
          >
            <v-col
              cols="12"
              md="3"
              lg="3"
              xl="3"
              class="pa-5 text-h6 font-weight-bold custom-table-cell"
            >
              <div>{{ attachmentItem.title }}</div>
            </v-col>
            <v-col
              cols="12"
              md="9"
              lg="9"
              xl="9"
              class="pa-5 custom-table-cell background-cell"
            >
              <v-container fluid class="pa-0 ma-0">
                <v-row no-gutters class="pa-0 ma-0">
                  <v-col cols="8">
                    <v-text-field
                      hide-details
                      dense
                      color="reform_primary"
                      v-model="attachmentItem.filename"
                      @click="fiClick(index)"
                    >
                    </v-text-field>
                    <input
                      style="display: none"
                      class="file-upload"
                      :id="'fi_' + index"
                      type="file"
                      @change="onFileChange(index, $event)"
                      accept="image/gif, image/jpeg, image/png"
                    />
                  </v-col>
                  <v-col cols="4" class="text-right">
                    <v-btn
                      class="ml-2"
                      color="reform_primary"
                      @click="deletePicture(index)"
                      elevation="0"
                      rounded
                      dark
                    >
                      첨부 삭제
                    </v-btn>
                  </v-col>
                  <v-col cols="12">
                    <v-subheader
                      class="ml-0 pa-0 ma-0"
                      v-html="computedLine(attachmentItem.warn)"
                      v-if="attachmentItem.filename === ''"
                    ></v-subheader>
                    <v-img
                      v-else
                      class="mt-5"
                      max-height="300px"
                      contain
                      :src="attachmentItem.url"
                      @click="download(index)"
                    >
                    </v-img>
                  </v-col>
                </v-row>
              </v-container>
            </v-col>
          </v-row>
          <!-- 병원소개 -->
          <v-row
            no-gutters
            justify="center"
            align="center"
            class="custom-table-row"
          >
            <v-col
              cols="12"
              md="3"
              lg="3"
              xl="3"
              class="pa-5 text-h6 font-weight-bold custom-table-cell"
            >
              <div>병원소개</div>
            </v-col>
            <v-col
              cols="12"
              md="9"
              lg="9"
              xl="9"
              class="pa-5 custom-table-cell background-cell"
            >
              <v-row align="center" justify="start" no-gutters>
                <v-col cols="12">
                  <v-textarea
                    hide-details
                    outlined
                    dense
                    v-model="form.introduceHospital.value"
                    @keyup="checkIntroduceHospital($event)"
                    @blur="checkIntroduceHospital($event)"
                    maxlength="100"
                    no-resize
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <!-- 병원소개 상세 -->
          <v-row
            no-gutters
            justify="center"
            align="center"
            class="custom-table-row"
          >
            <v-col
              cols="12"
              md="3"
              lg="3"
              xl="3"
              class="pa-5 text-h6 font-weight-bold custom-table-cell"
            >
              <div>병원소개 상세</div>
            </v-col>
            <v-col
              cols="12"
              md="9"
              lg="9"
              xl="9"
              class="pa-5 custom-table-cell background-cell"
            >
              <v-row align="center" justify="start" no-gutters>
                <v-col cols="12">
                  <v-textarea
                    hide-details
                    dense
                    outlined
                    v-model="form.introduceHospitalDetail.value"
                    @keyup="checkIntroduceHospitalDetail($event)"
                    @blur="checkIntroduceHospitalDetail($event)"
                    maxlength="200"
                    no-resize
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <!-- 의사소개 -->
          <v-row
            no-gutters
            justify="center"
            align="center"
            class="custom-table-row"
          >
            <v-col
              cols="12"
              md="3"
              lg="3"
              xl="3"
              class="pa-5 text-h6 font-weight-bold custom-table-cell"
            >
              <div>의사소개</div>
            </v-col>
            <v-col
              cols="12"
              md="9"
              lg="9"
              xl="9"
              class="pa-5 custom-table-cell background-cell"
            >
              <v-row align="center" justify="start" no-gutters>
                <v-col cols="12">
                  <v-textarea
                    hide-details
                    dense
                    outlined
                    v-model="form.introduceDoctor.value"
                    @keyup="checkIntroduceDoctor($event)"
                    @blur="checkIntroduceDoctor($event)"
                    maxlength="200"
                    no-resize
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <!-- 의사약력 -->
          <v-row
            no-gutters
            justify="center"
            align="center"
            class="custom-table-row"
          >
            <v-col
              cols="12"
              md="3"
              lg="3"
              xl="3"
              class="pa-5 text-h6 font-weight-bold custom-table-cell"
            >
              <div>의사약력</div>
            </v-col>
            <v-col
              cols="12"
              md="9"
              lg="9"
              xl="9"
              class="pa-5 custom-table-cell background-cell"
            >
              <v-row align="center" justify="start" no-gutters>
                <v-col cols="12">
                  <v-textarea
                    hide-details
                    dense
                    outlined
                    v-model="form.historyDoctor.value"
                    @keyup="checkHistoryDoctor($event)"
                    @blur="checkHistoryDoctor($event)"
                    maxlength="200"
                    no-resize
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <!-- 진료시간 -->
          <v-row
            no-gutters
            justify="center"
            align="center"
            class="custom-table-row"
          >
            <v-col
              cols="12"
              md="3"
              lg="3"
              xl="3"
              class="pa-5 text-h6 font-weight-bold custom-table-cell"
            >
              <div>진료시간</div>
            </v-col>
            <v-col
              cols="12"
              md="9"
              lg="9"
              xl="9"
              class="pa-5 custom-table-cell background-cell"
            >
              <v-row align="center" justify="start" no-gutters>
                <v-col cols="12">
                  <v-textarea
                    hide-details
                    dense
                    outlined
                    v-model="form.treatmentTime.value"
                    @keyup="checkTreatmentTime($event)"
                    @blur="checkTreatmentTime($event)"
                    maxlength="200"
                    no-resize
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <!-- 홈페이지 -->
          <v-row
            no-gutters
            justify="center"
            align="center"
            class="custom-table-row"
          >
            <v-col
              cols="12"
              md="3"
              lg="3"
              xl="3"
              class="pa-5 text-h6 font-weight-bold custom-table-cell"
            >
              <div>홈페이지</div>
            </v-col>
            <v-col
              cols="12"
              md="9"
              lg="9"
              xl="9"
              class="pa-5 custom-table-cell background-cell"
            >
              <v-row align="center" justify="start" no-gutters>
                <v-col cols="12">
                  <v-text-field
                    outlined
                    dense
                    hide-details
                    placeholder="홈페이지를 입력해주세요"
                    v-model="form.homepage.value"
                    @keyup="checkHomepage()"
                    @blur="checkHomepage()"
                    maxlength="50"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <div>
                    **기존에 보유하고 계신 병원 홈페이지가 존재하는 경우, 링크를
                    입력해주시기 바랍니다.
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <!-- 예약 웹/앱 주소 -->
          <v-row
            no-gutters
            justify="center"
            align="center"
            class="custom-table-row"
          >
            <v-col
              cols="12"
              md="3"
              lg="3"
              xl="3"
              class="pa-5 text-h6 font-weight-bold custom-table-cell"
            >
              <div>예약 웹/앱주소</div>
            </v-col>
            <v-col
              cols="12"
              md="9"
              lg="9"
              xl="9"
              class="pa-5 custom-table-cell background-cell"
            >
              <v-row align="center" justify="start" no-gutters>
                <v-col cols="12">
                  <v-text-field
                    outlined
                    dense
                    hide-details
                    placeholder="예약 앱/웹주소를 입력해주세요"
                    v-model="form.resvPage.value"
                    @keyup="checkReservationPage()"
                    @blur="checkReservationPage()"
                    maxlength="200"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <!-- 소개정보 추가 사용유무 -->
          <v-row
            no-gutters
            justify="center"
            align="center"
            class="custom-table-row"
          >
            <v-col
              cols="12"
              md="3"
              lg="3"
              xl="3"
              class="pa-5 text-h6 font-weight-bold custom-table-cell"
            >
              <div>소개정보 추가 사용유무</div>
            </v-col>
            <v-col
              cols="12"
              md="9"
              lg="9"
              xl="9"
              class="pa-5 custom-table-cell background-cell"
            >
              <v-row align="center" justify="start" no-gutters>
                <v-col cols="auto">
                  <v-switch
                    @input="checkEditor()"
                    v-model="view.isEditorMenu"
                    color="reform_primary"
                    class="font-weight-bold"
                    inset
                  ></v-switch>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <!-- 소개정보 추가제목 -->
          <v-row
            v-if="view.isEditorMenu"
            no-gutters
            justify="center"
            align="center"
            class="custom-table-row"
          >
            <v-col
              cols="12"
              md="3"
              lg="3"
              xl="3"
              class="pl-5 pr-5 text-h6 font-weight-bold custom-table-cell"
            >
              <div>소개정보 추가 제목</div>
            </v-col>
            <v-col
              cols="12"
              md="9"
              lg="9"
              xl="9"
              class="pa-5 custom-table-cell background-cell"
            >
              <v-row align="center" justify="start" no-gutters>
                <v-text-field
                  outlined
                  hide-details
                  placeholder="제목을 입력해주세요"
                  v-model="form.editor.title"
                  maxlength="50"
                ></v-text-field>
              </v-row>
            </v-col>
          </v-row>
          <!-- 소개정보 추가내용 -->
          <v-row
            v-if="view.isEditorMenu"
            no-gutters
            justify="center"
            align="center"
            class="custom-table-row"
          >
            <v-col
              cols="12"
              md="3"
              lg="3"
              xl="3"
              class="pl-5 pr-5 text-h6 font-weight-bold custom-table-cell"
            >
              <div>소개정보 추가 내용</div>
            </v-col>
            <v-col
              cols="12"
              md="9"
              lg="9"
              xl="9"
              class="pa-5 custom-table-cell background-cell"
            >
              <v-row align="center" justify="start" no-gutters>
                <div class="editor-wrap form">
                  <editor
                    :editorData="form.editor.content"
                    @sendEditContent="synchronization"
                    class="editor-form"
                  ></editor>
                </div>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
      <!-- 테이블끝 -->
    </v-row>
    <v-row justify="center">
      <v-btn
        class="mr-5"
        x-large
        rounded
        width="150px"
        elevation="0"
        color="grey darken-2"
        dark
        @click="prev()"
        v-if="form.sampleType.value !== '' && form.sampleType.value !== null"
      >
        <v-img src="@/assets/images/menuIcon/prev_mini.svg"></v-img>이전으로
      </v-btn>
      <v-btn
        x-large
        rounded
        width="150px"
        elevation="0"
        color="reform_primary"
        dark
        @click="storeInView(view.isModifyMode)"
      >
        신청서 작성
      </v-btn>
    </v-row>
    <button id="focusControl"></button>
    <default-popup v-if="checkPopup" :popupSet="popupSet" />
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapState, mapMutations } from "vuex";
import DefaultPopup from "../modal/DefaultPopup.vue";
import Editor from "@/views/util/Editor";
export default {
  components: { DefaultPopup, Editor },
  data() {
    return {
      radioValues: "",
      popupSet: {},
      form: {
        prodCd: "",
        aplyConts: "",
        hospNmKr: "",
        hospNmEn: "",
        jinConts: "",
        jinItems: "",
        attachment: [
          {
            isDeleted: null,
            flag: false,
            title: "의사 프로필 사진",
            source: null,
            url: "",
            filename: "",
            warn: "그림파일의 크기는 최소 640*480픽셀을 권장합니다.",
            text: "",
          },
          {
            isDeleted: null,
            flag: false,
            title: "병원 로고",
            source: null,
            url: "",
            filename: "",
            warn: "그림파일의 크기는 최소 640*480픽셀을 권장합니다.",
            text: "",
          },
          {
            isDeleted: null,
            flag: false,
            title: "병원 사진",
            source: null,
            url: "",
            filename: "",
            warn: "그림파일의 크기는 최소 640*480픽셀을 권장합니다.",
            text: "",
            value: "",
          },
          {
            isDeleted: null,
            flag: false,
            title: "병원 약도",
            source: null,
            url: "",
            filename: "",
            warn: "그림파일의 크기는 최소 640*480픽셀을 권장합니다.",
            text: "**병원 약도는 입력한 주소를 기반으로 네이버 지도를 통해 우선 저장 되었습니다.\n\n개별 약도그림으로 교체를 원하시는 경우 '파일첨부'를 통해\n원하시는 이미지파일을 저장해주시길 바랍니다.",
          },
          {
            isDeleted: null,
            flag: false,
            title: "PR Image",
            source: null,
            url: "",
            filename: "",
            warn: "그림파일의 크기는 최소 640*480픽셀을 권장합니다.",
            text: "",
          },
        ],
        tel: {
          value: "",
          flag: false,
          hasError: false,
          errorTxt: "*-와 숫자를 입력해주세요",
        },
        fax: {
          value: "",
          flag: false,
          hasError: false,
          errorTxt: "*-와 숫자를 입력해주세요",
        },
        introduceHospital: {
          value: "",
          flag: false,
          hasError: false,
          errorTxt: "*병원소개를 입력해주세요",
        },
        introduceHospitalDetail: {
          value: "",
          flag: false,
          hasError: false,
          errorTxt: "*병원소개 상세를 입력해주세요",
        },
        introduceDoctor: {
          value: "",
          flag: false,
          hasError: false,
          errorTxt: "*의사소개를 입력해주세요",
        },
        introduceDoctor2: {
          value: "",
          flag: false,
          hasError: false,
          errorTxt: "*의사소개를 입력해주세요",
        },
        introduceDoctor3: {
          value: "",
          flag: false,
          hasError: false,
          errorTxt: "*의사소개를 입력해주세요",
        },
        introduceDoctor4: {
          value: "",
          flag: false,
          hasError: false,
          errorTxt: "*의사소개를 입력해주세요",
        },
        introduceDoctor5: {
          value: "",
          flag: false,
          hasError: false,
          errorTxt: "*의사소개를 입력해주세요",
        },
        historyDoctor: {
          value: "",
          flag: false,
          hasError: false,
          errorTxt: "*의사약력을 입력해주세요",
        },
        treatmentTime: {
          value: "",
          flag: false,
          hasError: false,
          errorTxt: "*진료시간을 입력해주세요",
        },
        homepage: {
          type: ["홈페이지"],
          value: "",
          flag: false,
        },
        sampleType: {
          options: ["A형", "B형", "C형", "D형"],
          value: "" || "항목 선택",
        },
        templateType: {
          options: ["A형", "B형", "C형", "D형", "E형"],
          value: "" || "항목 선택",
          flag: false,
          hasError: false,
          errorTxt: "*시안을 선택해주세요",
        },
        subPromotionPageList: {
          options: ["블로그", "페이스북", "트위터", "카페", "기타", "선택안함"],
          item: [
            {
              type: "" || "항목 선택",
              value: "",
              flag: false,
              hasError: false,
              errorTxt: "기타 홈페이지 종류를 선택해주세요",
            },
            {
              type: "" || "항목 선택",
              value: "",
              flag: false,
              hasError: false,
              errorTxt: "기타 홈페이지 종류를 선택해주세요",
            },
            {
              type: "" || "항목 선택",
              value: "",
              flag: false,
              hasError: false,
              errorTxt: "기타 홈페이지 종류를 선택해주세요",
            },
          ],
          isChecked: false,
        },
        resvPage: {
          type: ["예약페이지"],
          value: "",
          flag: false,
        },
        editor: {
          title: "",
          content: "",
        },
      },
      view: {
        pageOrder: null,
        isModifyMode: false,
        btnName: "",
        isEditorMenu: false,
        isFooterVisible: false,
      },
      memberInfo: {
        hospNm: null,
        drNm: null,
        drId: null,
        addr: null,
        addrDtl: null,
      },
      imageMap: [
        {
          value: null,
        },
        {
          value: null,
        },
        {
          value: null,
        },
        {
          value: null,
        },
      ],
      multiFile: [
        {
          length: 0,
          fileNm: undefined,
          name: undefined,
        },
      ],
    };
  },
  created() {
    if (history.pushState !== undefined) {
      history.pushState({}, "", location.href);
    }
    this.getBasicData();
    this.getPromotionData();
    if (this.GET_PASSED_URL !== null) {
      this.checkPastUrl();
    }
    this.checkApplyId();
  },
  computed: {
    ...mapGetters("util", ["GET_PDF_URL", "GET_PASSED_URL"]),
    ...mapGetters("join", ["GET_BASIC"]),
    ...mapGetters("promotion", ["GET_PROMOTION"]),
    ...mapState("basic", ["checkPopup"]),
    computedLine() {
      return (text) => {
        if (text.indexOf("\n") !== -1) {
          return text.split("\n").join("<br />");
        } else {
          return text;
        }
      };
    },
  },
  methods: {
    ...mapActions("prod", ["SAVE_APPLY", "BRING_INPROGRESS_APPLY"]),
    ...mapActions("promotion", ["FETCH_PROMOTION", "PROMOTION_MODIFY"]),
    ...mapMutations("basic", ["SET_POPUP"]),
    ...mapActions("join", ["FETCH_BASIC"]),
    async getBasicData() {
      try {
        await this.FETCH_BASIC().then(() => {
          // console.log("찾기 회원정보", this.GET_BASIC);
          if (this.GET_BASIC.hospNm !== null) {
            this.memberInfo.hospNm = this.GET_BASIC.hospNm;
          }
          if (this.GET_BASIC.drNm !== null) {
            this.memberInfo.drNm = this.GET_BASIC.drNm;
          }
          if (this.GET_BASIC.drId !== null) {
            this.memberInfo.drId = this.GET_BASIC.drId;
          }
          if (this.GET_BASIC.addr !== null) {
            this.memberInfo.addr = this.GET_BASIC.addr;
          }
          if (this.GET_BASIC.addrDtl !== null) {
            this.memberInfo.addrDtl = this.GET_BASIC.addrDtl;
          }
        });
      } catch (error) {
        console.log("error:", error);
      }
    },
    async getPromotionData() {
      try {
        await this.FETCH_PROMOTION().then(() => {
          if (
            this.GET_PROMOTION.hospIntroType !== null &&
            this.GET_PROMOTION.hospIntroType !== ""
          ) {
            if (this.GET_PROMOTION.hospIntroType === "0") {
              this.form.sampleType.value = "C형";
            } else if (this.GET_PROMOTION.hospIntroType === "1") {
              this.form.sampleType.value = "D형";
            } else if (this.GET_PROMOTION.hospIntroType === "2") {
              this.form.sampleType.value = "E형";
            } else if (this.GET_PROMOTION.hospIntroType === "3") {
              this.form.sampleType.value = "B형";
            } else if (this.GET_PROMOTION.hospIntroType === "4") {
              this.form.sampleType.value = "A형";
            }
          }
          this.form.tel.value =
            this.GET_PROMOTION.hospTel === null
              ? ""
              : this.phoneFomatter(this.GET_PROMOTION.hospTel);
          this.form.tel.flag =
            this.GET_PROMOTION.hospTel !== null ? true : false;
          if (this.GET_PROMOTION.doctIntro !== null) {
            this.view.isModifyMode = true;
            this.view.btnName = "신청서 작성";
            if (
              this.GET_PROMOTION.drPicAttchId !== null &&
              this.GET_PROMOTION.drPicAttchId !== ""
            ) {
              this.form.attachment[0].url =
                "https://www.drrefer.net/api/pic/" +
                this.GET_PROMOTION.drPicAttchId;
              this.form.attachment[0].filename =
                this.GET_PROMOTION.drPicAttchNm;
              this.imageMap[0].value = this.GET_PROMOTION.drPicAttchNm;
            }
            if (
              this.GET_PROMOTION.logoAttchId !== null &&
              this.GET_PROMOTION.logoAttchId !== ""
            ) {
              this.form.attachment[1].url =
                "https://www.drrefer.net/api/pic/" +
                this.GET_PROMOTION.logoAttchId;
              this.form.attachment[1].filename = this.GET_PROMOTION.logoAttchNm;
              this.imageMap[1].value = this.GET_PROMOTION.logoAttchNm;
            }
            if (
              this.GET_PROMOTION.picAttchId !== null &&
              this.GET_PROMOTION.picAttchId !== ""
            ) {
              this.form.attachment[2].url =
                "https://www.drrefer.net/api/pic/" +
                this.GET_PROMOTION.picAttchId;
              this.form.attachment[2].filename = this.GET_PROMOTION.picAttchNm;
              this.imageMap[2].value = this.GET_PROMOTION.picAttchNm;
            }
            if (
              this.GET_PROMOTION.mapAttchId !== null &&
              this.GET_PROMOTION.mapAttchId !== ""
            ) {
              this.form.attachment[3].url =
                "https://www.drrefer.net/api/pic/" +
                this.GET_PROMOTION.mapAttchId;
              this.form.attachment[3].filename = this.GET_PROMOTION.mapAttchNm;
              this.imageMap[3].value = this.GET_PROMOTION.mapAttchNm;
            }
            if (
              this.GET_PROMOTION.prAttchId !== null &&
              this.GET_PROMOTION.prAttchId !== ""
            ) {
              // console.log("qweqweqew0");
              // console.log(this.GET_PROMOTION);
              // console.log(this.form.attachment[4]);
              this.form.attachment[4].url =
                "https://www.drrefer.net/api/pic/" +
                this.GET_PROMOTION.prAttchId;
              this.form.attachment[4].filename = this.GET_PROMOTION.prAttchNm;
            }
            if (
              this.GET_PROMOTION.docAddvTitle !== null &&
              this.GET_PROMOTION.docAddvTitle !== ""
            ) {
              this.form.editor.title = this.GET_PROMOTION.docAddvTitle;
              this.form.editor.content = this.GET_PROMOTION.docAddvConts;
              this.$nextTick((_) => {
                this.view.isEditorMenu = true;
              });
            }
            this.form.attachment[0].isDeleted = 0;
            this.form.attachment[1].isDeleted = 0;
            this.form.attachment[2].isDeleted = 0;
            this.form.attachment[3].isDeleted = 0;
            this.form.attachment[4].isDeleted = 0;

            this.form.fax.value =
              this.GET_PROMOTION.hospFaxNo === null
                ? ""
                : this.phoneFomatter(this.GET_PROMOTION.hospFaxNo);
            this.form.fax.flag =
              this.GET_PROMOTION.hospFaxNo !== null ? true : false;
            this.form.introduceHospital.value =
              this.GET_PROMOTION.hospIntro === null
                ? ""
                : this.GET_PROMOTION.hospIntro;
            this.form.introduceHospital.flag =
              this.GET_PROMOTION.hospIntro !== null ? true : false;
            this.form.introduceHospitalDetail.value =
              this.GET_PROMOTION.conConts === null
                ? ""
                : this.GET_PROMOTION.conConts;
            this.form.introduceHospitalDetail.flag =
              this.GET_PROMOTION.conConts !== null ? true : false;
            this.form.introduceDoctor.value =
              this.GET_PROMOTION.doctIntro === null
                ? ""
                : this.GET_PROMOTION.doctIntro;
            this.form.introduceDoctor.flag =
              this.GET_PROMOTION.doctIntro !== null ? true : false;
            this.form.historyDoctor.value =
              this.GET_PROMOTION.drHist === null
                ? ""
                : this.GET_PROMOTION.drHist;
            this.form.historyDoctor.flag =
              this.GET_PROMOTION.drHist !== null ? true : false;
            this.form.treatmentTime.value =
              this.GET_PROMOTION.etcConts === null
                ? ""
                : this.GET_PROMOTION.etcConts;
            this.form.treatmentTime.flag =
              this.GET_PROMOTION.etcConts !== null ? true : false;
            this.form.homepage.value =
              this.GET_PROMOTION.homepage === null
                ? ""
                : this.GET_PROMOTION.homepage;
            this.form.homepage.flag =
              this.GET_PROMOTION.homepage !== null ? true : false;
            this.form.subPromotionPageList.item[0].type =
              this.GET_PROMOTION.urlNm1 === null
                ? ""
                : this.GET_PROMOTION.urlNm1;
            this.form.subPromotionPageList.item[0].value =
              this.GET_PROMOTION.url1 === null ? "" : this.GET_PROMOTION.url1;
            this.form.subPromotionPageList.item[0].flag =
              this.GET_PROMOTION.url1 !== null ? true : false;
            this.form.subPromotionPageList.item[1].type =
              this.GET_PROMOTION.urlNm2 === null
                ? ""
                : this.GET_PROMOTION.urlNm2;
            this.form.subPromotionPageList.item[1].value =
              this.GET_PROMOTION.url2 === null ? "" : this.GET_PROMOTION.url2;
            this.form.subPromotionPageList.item[1].flag =
              this.GET_PROMOTION.url2 !== null ? true : false;
            this.form.subPromotionPageList.item[2].type =
              this.GET_PROMOTION.urlNm3 === null
                ? ""
                : this.GET_PROMOTION.urlNm3;
            this.form.subPromotionPageList.item[2].value =
              this.GET_PROMOTION.url3 === null ? "" : this.GET_PROMOTION.url3;
            this.form.subPromotionPageList.item[2].flag =
              this.GET_PROMOTION.url3 !== null ? true : false;
            this.form.resvPage.value =
              this.GET_PROMOTION.rsvUrl === null
                ? ""
                : this.GET_PROMOTION.rsvUrl;
            this.form.resvPage.flag =
              this.GET_PROMOTION.rsvUrl !== null ? true : false;
          } else {
            this.view.isModifyMode = false;
            this.view.btnName = "신청서 작성";
            this.view.pageOrder = null;
            //this.loadRef(0);
            if (
              this.GET_PROMOTION.mapAttchId !== null &&
              this.GET_PROMOTION.mapAttchId !== ""
            ) {
              this.form.attachment[3].url =
                "https://www.drrefer.net/api/pic/" +
                this.GET_PROMOTION.mapAttchId;
              this.form.attachment[3].isDeleted = 0;
              this.form.attachment[3].filename = this.GET_PROMOTION.mapAttchNm;
              this.imageMap[3].value = this.GET_PROMOTION.mapAttchNm;
            }
          }
        });
      } catch (error) {
        console.log("error:", error);
      }
    },
    async checkApplyId() {
      const inqParam = {
        drId: localStorage.getItem("drId"),
      };

      const transData = new FormData();
      const transedInqParam = JSON.stringify(inqParam);
      transData.append("inqParam", transedInqParam);
      await this.BRING_INPROGRESS_APPLY(transData).then((data) => {
        // console.log(data.param);
        // console.log(data.list);
        if (data.param !== undefined) {
          if (data.param.doctIntro2 !== "" && data.param.doctIntro2 !== null) {
            this.form.introduceDoctor2.value = data.param.doctIntro2;
            // console.log(this.form.introduceDoctor2.value);
          }
          if (data.param.doctIntro3 !== "" && data.param.doctIntro3 !== null) {
            this.form.introduceDoctor3.value = data.param.doctIntro3;
          }
          if (data.param.doctIntro4 !== "" && data.param.doctIntro4 !== null) {
            this.form.introduceDoctor4.value = data.param.doctIntro4;
          }
          if (data.param.doctIntro5 !== "" && data.param.doctIntro5 !== null) {
            this.form.introduceDoctor5.value = data.param.doctIntro5;
          }
          if (data.param.jinConts !== "" && data.param.jinConts !== null) {
            this.form.jinConts = data.param.jinConts;
          }
          if (data.param.jinItems !== "" && data.param.jinItems !== null) {
            this.form.jinItems = data.param.jinItems;
          }
          if (data.param.jinItems !== "" && data.param.jinItems !== null) {
            this.form.hospNmEn = data.param.hospEngNm;
          }
          if (data.param.prodCd !== "" && data.param.prodCd !== null) {
            this.form.prodCd = data.param.prodCd;
          }
          ////////////////////////////////////////////////////////////////////////////
          if (this.form.prodCd === "") {
            // console.log(localStorage.getItem("prodCd"));
            if (localStorage.getItem("prodCd") === "10010") {
              this.selectItems = "A형";
            } else if (localStorage.getItem("prodCd") === "10020") {
              this.selectItems = "B형";
            } else if (localStorage.getItem("prodCd") === "10030") {
              this.selectItems = "C형";
            }
          } else {
            localStorage.setItem("prodCd", this.form.prodCd);
            if (this.form.prodCd === "10010") {
              this.selectItems = "A형";
            } else if (this.form.prodCd === "10020") {
              this.selectItems = "B형";
            } else if (this.form.prodCd === "10030") {
              this.selectItems = "C형";
            }
          }
          ///////////////////////////////////////////////////////////////////////////
          if (data.list !== "" && data.list !== null) {
            this.multiFile = data.list;
            if (this.multiFile.length === 0) {
              this.multiFile = [
                {
                  length: 0,
                  fileNm: undefined,
                  name: undefined,
                },
              ];
            }
            // console.log(this.multiFile);
          }
        } else {
          if (this.form.prodCd === "") {
            // console.log(localStorage.getItem("prodCd"));
            if (localStorage.getItem("prodCd") === "10010") {
              this.selectItems = "A형";
            } else if (localStorage.getItem("prodCd") === "10020") {
              this.selectItems = "B형";
            } else if (localStorage.getItem("prodCd") === "10030") {
              this.selectItems = "C형";
            }
          } else {
            localStorage.setItem("prodCd", this.form.prodCd);
            if (this.form.prodCd === "10010") {
              this.selectItems = "A형";
            } else if (this.form.prodCd === "10020") {
              this.selectItems = "B형";
            } else if (this.form.prodCd === "10030") {
              this.selectItems = "C형";
            }
          }
        }
      });
    },
    async storeInView(type) {
      try {
        const prParam = {
          drId: localStorage.getItem("drId"),
          isDeletedDrPicAttchId: this.form.attachment[0].isDeleted,
          isDeletedLogoAttchId: this.form.attachment[1].isDeleted,
          isDeletedPicAttchId: this.form.attachment[2].isDeleted,
          isDeletedMapAttchId: this.form.attachment[3].isDeleted,
          isDeletedPrAttchId: this.form.attachment[4].isDeleted,
          hospTel: this.form.tel.value.replace(/-/gi, ""),
          hospFaxNo: this.form.fax.value.replace(/-/gi, ""),
          hospIntro: this.form.introduceHospital.value,
          conConts: this.form.introduceHospitalDetail.value,
          doctIntro: this.form.introduceDoctor.value,
          drHist: this.form.historyDoctor.value,
          etcConts: this.form.treatmentTime.value,
          homepage:
            this.form.homepage.value === "" || this.form.homepage.value === null
              ? null
              : this.form.homepage.value,
          url1:
            this.form.subPromotionPageList.item[0].value === "" ||
            this.form.subPromotionPageList.item[0].value === null
              ? ""
              : this.form.subPromotionPageList.item[0].value,
          urlNm1: "기타",
          url2:
            this.form.subPromotionPageList.item[1].value === "" ||
            this.form.subPromotionPageList.item[1].value === null
              ? ""
              : this.form.subPromotionPageList.item[1].value,
          urlNm2: "기타",
          url3:
            this.form.subPromotionPageList.item[2].value === "" ||
            this.form.subPromotionPageList.item[2].value === null
              ? ""
              : this.form.subPromotionPageList.item[2].value,
          urlNm3: "기타",
          rsvUrl:
            this.form.resvPage.value === "" || this.form.resvPage.value === null
              ? null
              : this.form.resvPage.value,
          mapAttchId: this.GET_PROMOTION.mapAttchId,
          logoAttchId: this.GET_PROMOTION.logoAttchId,
          picAttchId: this.GET_PROMOTION.picAttchId,
          drPicAttchId: this.GET_PROMOTION.drPicAttchId,
          prAttchId: this.GET_PROMOTION.prAttchId,
          mapType:
            this.form.attachment[3].source !== null
              ? "U"
              : this.GET_PROMOTION.mapType,
        };
        if (this.view.isEditorMenu) {
          if (this.GET_PROMOTION.docAddvTitle !== null) {
            prParam.docAddvTitle = this.form.editor.title;
            prParam.docAddvConts = this.form.editor.content;
          } else {
            if (this.form.editor.title !== "") {
              prParam.docAddvTitle = this.form.editor.title;
              prParam.docAddvConts = this.form.editor.content;
            }
          }
        }
        if (
          prParam.hospIntroType === 3 &&
          this.form.attachment[0].url === null
        ) {
          // console.log(this.form.attachment[0]);
          prParam.hospIntroType = 4;
        }
        // console.log("pr파람데이터 :");
        // console.log(prParam);

        /** 벡엔드 스트링으로 파싱하기 때문에 직렬화를해준다. */
        const transedPrParam = JSON.stringify(prParam);
        const transData = new FormData();
        const transData1 = new FormData();
        transData.append("prParam", transedPrParam);
        if (type) {
          transData.append(
            "drPicFile",
            this.form.attachment[0].source === null
              ? this.GET_PROMOTION.drPicAttchId
              : this.form.attachment[0].source
          );
          transData.append(
            "logoFile",
            this.form.attachment[1].source === null
              ? this.GET_PROMOTION.logoAttchId
              : this.form.attachment[1].source
          );
          /////////////////////////////

          if (this.selectItems === "A형") {
            transData.append(
              "picFile",
              this.form.attachment[2].source === null
                ? this.GET_PROMOTION.picAttchId
                : this.form.attachment[2].source
            );
          } else {
            // console.log(this.multiFile);
            if (this.multiFile !== "" && this.multiFile !== null) {
              for (var i = 0; i < this.multiFile.length; i++) {
                // console.log(this.multiFile[i]);
                transData1.append("picFile", this.multiFile[i]);
              }
            }
          }
          transData.append(
            "prFile",
            this.form.attachment[4].source === null
              ? this.GET_PROMOTION.picAttchId
              : this.form.attachment[4].source
          );
        }
        transData.append(
          "mapFile",
          this.form.attachment[3].source === null
            ? this.GET_PROMOTION.mapAttchId
            : this.form.attachment[3].source
        );
        // console.log("전송할 데이터(전체) :", transData);
        await this.PROMOTION_MODIFY(transData).then(async () => {
          const applyParam = {
            drId: localStorage.getItem("drId"),
            aplyConts: "",
            doctIntro2: this.form.introduceDoctor2.value,
            doctIntro3: this.form.introduceDoctor3.value,
            doctIntro4: this.form.introduceDoctor4.value,
            doctIntro5: this.form.introduceDoctor5.value,
            hospEngNm: this.form.hospNmEn,
            jinConts: this.form.jinConts,
            jinItems: this.form.jinItems,
            prodCd: localStorage.getItem("prodCd"),
          };
          const transedApplyParam = JSON.stringify(applyParam);

          transData1.append("applyParam", transedApplyParam);

          /*for (var pair of transData1.entries()) {
            console.log(pair[0]);
            console.log("/");
            console.log(pair[1]);
          }*/

          await this.SAVE_APPLY(transData1).then((data) => {
            if (data > 900) {
              this.SET_POPUP(true);
              this.popupSet.title = "소개정보 등록실패";
              this.popupSet.content = " 소개정보를 등록할 수 없습니다.";
              this.popupSet.popType = "warn";
              this.popupSet.cancelBtnText = "확인";
              return;
            }

            this.SET_POPUP(true);
            this.popupSet.title = "신청서 작성 및 소개정보 등록성공";
            this.popupSet.content =
              `신청처 작성이 완료 되었습니다. \n <b class="point">https://www.drrefer.net/dr/` +
              localStorage.getItem("drId") +
              "</b> 에서 \n 선택하신 내용의 변경사항을 확인하실 수 있습니다.";
            this.popupSet.popType = "warnNext";
            this.popupSet.cancelBtnText = "확인";
            this.popupSet.nextLink = "manageApply";
          });
        });
      } catch (error) {
        console.log("error: ", error);
        if (error.data.status !== 5000 && error.data.status !== 5002) {
          this.SET_POPUP(true);
          this.popupSet.title = "소개정보 등록실패";
          this.popupSet.content = " 소개정보를 등록할 수 없습니다.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
        }
      }
    },
    checkPastUrl() {
      if (this.GET_PASSED_URL === "manage") {
        this.isDetermined = true;
      }
    },
    phoneFomatter(number) {
      let formatNum = "";
      if (number.length == 11) {
        formatNum = number.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3");
      } else if (number.length == 8) {
        formatNum = number.replace(/(\d{4})(\d{4})/, "$1-$2");
      } else {
        // console.log("핵검증", number.indexOf("02"));
        if (number.indexOf("02") == 0) {
          if (number.length === 9) {
            formatNum = number.replace(/(\d{2})(\d{3})(\d{4})/, "$1-$2-$3");
          } else {
            formatNum = number.replace(/(\d{2})(\d{4})(\d{4})/, "$1-$2-$3");
          }
        } else {
          formatNum = number.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
        }
      }
      return formatNum;
    },
    fiClick(tab) {
      document.getElementById("fi_" + tab).click();
    },
    deletePicture(order) {
      this.form.attachment[order].isDeleted = 1;
      this.form.attachment[order].flag = false;
      this.form.attachment[order].source = null;
      this.form.attachment[order].url = "";
      this.form.attachment[order].filename = "";
    },
    download(order) {
      if (order === 0) {
        if (
          this.GET_PROMOTION.drPicAttchNm === this.form.attachment[0].filename
        ) {
          window.open(
            "https://www.drrefer.net/api/pic/" +
              this.GET_PROMOTION.drPicAttchId,
            "_blank"
          );
        }
      } else if (order === 1) {
        if (
          this.GET_PROMOTION.logoAttchNm === this.form.attachment[1].filename
        ) {
          window.open(
            "https://www.drrefer.net/api/pic/" + this.GET_PROMOTION.logoAttchId,
            "_blank"
          );
        }
      } else if (order === 2) {
        if (
          this.GET_PROMOTION.picAttchNm === this.form.attachment[2].filename
        ) {
          window.open(
            "https://www.drrefer.net/api/pic/" + this.GET_PROMOTION.picAttchId,
            "_blank"
          );
        }
      } else if (order === 3) {
        if (
          this.GET_PROMOTION.mapAttchNm === this.form.attachment[3].filename
        ) {
          window.open(
            "https://www.drrefer.net/api/pic/" + this.GET_PROMOTION.mapAttchId,
            "_blank"
          );
        }
      }
    },
    onFileChange(order, event) {
      // console.log("in");
      this.multiFile = [
        {
          length: 0,
          fileNm: undefined,
          name: undefined,
        },
      ];
      this.form.attachment[order].url = "";
      this.form.attachment[order].filename = "";
      this.form.attachment[order].flag = true;
      this.form.attachment[order].isDeleted = 0;
      const getFile = event.target.files;
      if (
        order === 2 &&
        (this.selectItems === "B형" || this.selectItems === "C형")
      ) {
        if (this.selectItems === "B형" && event.target.files.length >= 3) {
          alert(
            "B형 신청서 에서는 병원사진을 최대 3장까지 사용하실 수 있습니다."
          );
        } else {
          var fileArray = {};

          for (var i = 0; i < event.target.files.length; i++) {
            fileArray[i] = event.target.files[i];
            fileArray[i].url = URL.createObjectURL(event.target.files[i]);
          }

          this.multiFile = event.target.files;
        }
      } else {
        const getFileUrl = URL.createObjectURL(event.target.files[0]);
        this.form.attachment[order].source = event.target.files[0];
        /** getFile */
        this.form.attachment[order].url = getFileUrl;
        this.form.attachment[order].filename = getFile[0].name;
        this.form.attachment[order].flag = true;
        this.form.attachment[order].isDeleted = 0;
      }
    },
    change_radio(radioValue) {
      if (radioValue === "0") {
        this.form.templateType.value = "B형";
        this.form.sampleType.value = "B형";
        this.radioValues = radioValue;
      } else if (radioValue === "1") {
        this.form.templateType.value = "B형";
        this.form.sampleType.value = "B형";
        this.radioValues = radioValue;
      } else if (radioValue === "2") {
        this.form.templateType.value = "B형";
        this.form.sampleType.value = "B형";
        this.radioValues = radioValue;
      } else if (radioValue === "3") {
        this.form.templateType.value = "C형";
        this.form.sampleType.value = "C형";
        this.radioValues = radioValue;
      } else if (radioValue === "4") {
        this.form.templateType.value = "B형";
        this.form.sampleType.value = "B형";
        this.radioValues = radioValue;
      } else if (radioValue === "5") {
        this.form.templateType.value = "A형";
        this.form.sampleType.value = "A형";
        this.radioValues = radioValue;
      }
    },
    synchronization(editorData) {
      this.form.editor.content = editorData;
    },
    prev() {
      this.$router.push("manageApply");
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/PrManage";
input,
textarea {
  -moz-user-select: auto;

  -webkit-user-select: auto;

  -ms-user-select: auto;

  user-select: auto;
}
.custom-table {
  background-color: #f5f9fa;
  border-top: 2px solid black;
  border-bottom: 2px solid black;
}
.custom-table-cell {
  align-items: center;
  display: flex;
}
.custom-table-row {
  border-bottom: 1px solid lightgrey;
}
.background-cell {
  background-color: white;
  min-height: 120px;
}
.pr-manage-sample-btn {
  display: inline-block;
  width: 116px;
  &:last-child {
    margin-left: 10px;
    width: 150px;
  }
}
.pr-manage-radio {
  display: inline-block;
}
.pr-manage-sam-pre-div {
  display: inline-block;
}

.editor-form { 
  -moz-user-select: auto;

  -webkit-user-select: auto;

  -ms-user-select: auto;

  user-select: auto;
}

.ck-rounded-corners {
  -moz-user-select: auto;

  -webkit-user-select: auto;

  -ms-user-select: auto;

  user-select: auto;
}

@media screen and (max-width: 1100px) {
  .pr-manage-sam-pre-div {
    display: block;
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 958px) {
  .pr-manage-sam-pre-div {
    display: inline-block;
    margin-bottom: 0px;
  }
}

@media screen and (max-width: 570px) {
  .pr-manage-sam-pre-div {
    margin-bottom: 15px;
  }
}
@media screen and (max-width: 338px) {
  .pr-manage-sam-pre-div {
    text-align: center;
  }
  .pr-manage-sample-btn {
    &:first-child {
      margin-bottom: 15px;
    }
  }
}
</style>
